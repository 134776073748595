const darkGrey = '#414141';
const grey = '#707070';
const mediumGrey = '#d4d4d4';
const lightGrey = '#F8F8F8';
const mainBlue = '#14397D';
const mainGreen = '#03B982';
const white = '#FFFFFF';
const black = '#000000';

export const theme = {
  palette: {
    brandBlue: mainBlue,
    brandGreen: mainGreen,
    basicWhite: white,
    basicGrey: grey,
    backgroundGray: lightGrey,
    mediumGrey,
    darkGrey,
    black,
  },
  typography: {
    color: {
      darkGrey,
      grey,
      brandBlue: mainBlue,
      brandGreen: mainGreen,
      basicWhite: white,
    },
    size: {
      xxxl: '52px',
      xxl: '46px',
      xl: '32px',
      lg: '24px',
      md: '20px',
      xmd: '18px',
      basic: '16px',
      small: '14px',
      xs: '12px',
    },
    weight: {
      bold: 700,
      semiBold: 600,
      medium: 500,
      normal: 400,
      light: 200,
    }
  },
  spacing: {
    xxxl: '5rem',
    xxl: '4rem',
    xl: '2rem',
    lg: '1.75rem',
    md: '1.5rem',
    xmd: '1.25rem',
    basic: '1rem',
    sm: '0.75rem',
    xs: '0.5rem',
    xxs: '0.25rem',
  }
}
