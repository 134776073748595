export const MAIN_URL = '/';
export const PATTERN_APPLIED_URL = 'https://patternapplied.com';

export const CONTACT_EMAIL_ADDRESS = 'piotr@patternapplied.com';

export const HOME_PAGE_TITLE = 'Kurs DDD/CQRS/ES Applied on JVM with Axon Framework';

export const PRIVACY_POLICY_LINK = `${PATTERN_APPLIED_URL}/polityka-prywatnosci/`

export const MEDIA = {
  MIN: '768px',
  MED: '992px',
  MAX: '1200px',
}


